<template>
  <div class="container">
  </div>
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  async beforeCreate() {
    $fetch
      .post("WeixinMiniAppShortUrl", {
        path: 'pages/profile/index',
        key: "017ccf71-a085-1080-4561-99377384d127",
        queryParams: { userId: "018b64ee-0522-2ec3-e1d3-31913983b900" },
        type: 2,
      })
      .then((res) => {
        window.location.href = res.short_url;
      });
  }
}
</script>

<style>

</style>
